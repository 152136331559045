











import { Component, Prop, Vue } from "vue-property-decorator";
import Player from '@vimeo/player'
@Component
export default class VimeoPlayer extends Vue {
	@Prop({ required: true }) protected videoSrc!: string;
	@Prop({ required: false }) protected enableGoogleTracking!: boolean;
	@Prop({ required: false }) protected autoplay!: boolean;
	protected player!: Player;

	mounted(): void {
		this.player = new Player(this.$refs.vimeoPlayer as HTMLIFrameElement);
		if (this.autoplay){
			void this.play();			
		}
	}

	public get getPlayer(): Player {
		return this.player;
	}

	public async play(): Promise<void> {
		await this.player.play();
		if (this.enableGoogleTracking && typeof gtag === 'function') {
			// eslint-disable-next-line no-undef
			gtag('event', 'play', {
				event_category: 'video',
				event_label: this.videoSrc
			});
		}
	}

	public async pause(): Promise<void> {
		await this.player.pause();
	}

	public async setVideoProgress(seconds: number): Promise<void> {
		await this.player.setCurrentTime(seconds);
	}
}
