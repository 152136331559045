var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "conditional",
    {
      attrs: {
        condition: !_vm.isEmpty,
        "message-otherwise": _vm.messageIfEmpty,
        "apply-transition": _vm.useTransitionOnEmptying
      },
      scopedSlots: _vm._u(
        [
          {
            key: "replacement",
            fn: function() {
              return [_vm._t("empty")]
            },
            proxy: true
          }
        ],
        null,
        true
      )
    },
    [
      _c(
        "swiper",
        {
          ref: "swiper",
          class: _vm.effectiveOptions.cssClass,
          attrs: { options: _vm.effectiveOptions },
          on: { slideChange: _vm.slideChange, ready: _vm.onReady },
          scopedSlots: _vm._u([
            {
              key: "button-prev",
              fn: function() {
                return [
                  _vm.effectiveOptions.includeNavigationButtons
                    ? _c("div", {
                        staticClass: "swiper-button swiper-button-prev"
                      })
                    : _vm._e()
                ]
              },
              proxy: true
            },
            {
              key: "button-next",
              fn: function() {
                return [
                  _vm.effectiveOptions.includeNavigationButtons
                    ? _c("div", {
                        staticClass: "swiper-button swiper-button-next"
                      })
                    : _vm._e()
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _vm._l(_vm.virtualItems, function(itemRow, index) {
            return [
              _c(
                "swiper-slide",
                { key: _vm.listItemIndex(index) },
                _vm._l(itemRow, function(item, row) {
                  return _c("list-item-loader", {
                    key: row,
                    attrs: {
                      from: item,
                      "item-props": _vm.$attrs,
                      adapter: _vm.conversionAdapter,
                      component: _vm.options.component,
                      index: _vm.listItemIndex(index)
                    }
                  })
                }),
                1
              ),
              _vm._v(" "),
              _vm._t("customSlide", null, { index: index })
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }