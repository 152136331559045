/*
* Currently, as of early 2018, our webpack setup only ships a single bundle with all our typescript code.
* It would be nice to split this up further, however, we have not yet thought about about a good way of
* doing this, so feel free to make a proposal if you want to improve the situation.
*
* To make our typescript modules callable from javascript snippets we export many of the classes defined
* in typescript to the global "window" object. This is certainly a hack and we should eventually replace
* all these javascript snippets with proper.
 */

import { AdminNavigationBar } from "./controls/AdminNavigationBar";
import { Banner } from "./controls/Banner";
import { IHandlerType } from "./controls/base/IHandlerType";
import { ProgressBar } from "./controls/bootstrap/ProgressBar";
import { Carousel } from "./controls/Carousel";
import { ChangeGameVideoPriority } from "./controls/ChangeGameVideoPriority";
import { ForgotPasswordParent } from "./controls/ForgotPasswordParent";
import { BootstrapSlider } from "./controls/form/BootstrapSlider";
import { FeatureLevelSelect } from "./controls/form/FeatureLevelSelect";
import { PasswordInputGroup } from "./controls/form/PasswordInputGroup";
import { ChallengeListItem } from "./controls/list-item/ChallengeListItem";
import { LoginFormChildren } from "./controls/LoginFormChildren";
import { LoginFormParent } from "./controls/LoginFormParent";
import { CodeInputGroup } from "./controls/membership/CodeInputGroup";
import { ProductSelector } from "./controls/membership/ProductSelector";
import { PurchaseInformationBox } from "./controls/membership/PurchaseInformationBox";
import { PurchaseSummary } from "./controls/membership/PurchaseSummary";
import { ModerationNotifier } from "./controls/ModerationNotifier";
import { Navbar } from "./controls/Navbar";
import { NewspaperAdSaveButton } from "./controls/NewspaperAdSaveButton";
import { NewspaperEditor } from "./controls/NewspaperEditor";
import { Birthday } from "./controls/profile-page/Birthday";
import { ChildProfile } from "./controls/profile-page/ChildProfile";
import { ChildProfileInfoBlockEdit } from "./controls/profile-page/ChildProfileInfoBlockEdit";
import { EndFriendshipButton } from "./controls/profile-page/EndFriendshipButton";
import { Name } from "./controls/profile-page/Name";
import { ProfileEmailChange } from "./controls/ProfileEmailChange";
import { ProfileForm } from "./controls/ProfileForm";
import { StepWizard } from "./controls/step-wizard/StepWizard";
import { StatusMessageEditor } from "./controls/texteditor/StatusMessageEditor";
import { TextEditor } from "./controls/texteditor/TextEditor";
import { ToggleableControl } from "./controls/toggleable/ToggleableControl";
import { MemberList } from "./lists/MemberList";
import { ChangePassword } from "./parent-controls/ChangePassword";
import { ChildCredentials } from "./parent-controls/ChildCredentials";
import { CurrencyReward } from "./parent-controls/CurrencyReward";
import { FeatureLevelSettings } from "./parent-controls/FeatureLevelSettings";
import { AsyncCheckboxPanel } from "./parent-controls/form/checkbox/AsyncCheckboxPanel";
import { CheckboxPanel } from "./parent-controls/form/checkbox/CheckboxPanel";
import { DateCheckboxPanel } from "./parent-controls/form/checkbox/DateCheckboxPanel";
import { VideoCheckboxPanel } from "./parent-controls/form/checkbox/VideoCheckboxPanel";
import { SelectControlledControls } from "./webcontrols/SelectControlledControls";

import Vue from "vue";
import VueRouter from "vue-router";
import Vuex from "vuex";
import { useModule } from "vuex-simple";
import { createPinia, PiniaVuePlugin } from 'pinia'

import I18Next from "./Control/Vue/I18Next";

import { setup } from "./axios";

import MembershipDescription from "./Control/Vue/LandingPage/MembershipDescription.vue";
import MembershipFlipTiles from "./Control/Vue/LandingPage/MembershipFlipTiles.vue";
import SiteImpressum from "./Control/Vue/LandingPage/SiteImpressum.vue";
import VueList from "./Control/Vue/Lists/VueList/VueList.vue";
import Logo from "./layout/common/Logo.vue";

import Modal from "./Control/Vue/Notifications/Modal.vue";
import Notifier from "./Control/Vue/Notifications/Shared/Notifier";
import ConversionModuleRegistration from "./Control/Vue/Vuex/ConversionModuleRegistration";
import ListModuleRegistration from "./Control/Vue/Vuex/ListModuleRegistration";
import SelectedItemModuleRegistration from "./Control/Vue/Vuex/SelectedItemModuleRegistration.vue";
import I18NextModuleRegistration from "./Control/Vue/Vuex/I18NextModuleRegistration";

import DateSelector from "./Parents/Control/Vue/DateSelector.vue";
import Iframe from "./Parents/Control/Vue/Iframe.vue";
import CoverVideoPanel from "./Parents/Control/Vue/LandingPage/CoverVideoPanel.vue";
import VimeoPlayer from "./Control/Vue/VimeoPlayer.vue";
import RewardAndSocialFeaturesPanel from "./Parents/Control/Vue/LandingPage/RewardAndSocialFeaturesPanel.vue";
import AccessibilityInfoPanel from "./Parents/Control/Vue/LandingPage/AccessibilityInfoPanel.vue";
import Funds from "./common/Funds.vue";
import MiaPrepAnnouncement from "./common/MiaPrepAnnouncement.vue";
import Header from "./layout/children/Header.vue";

import { errorPrompt, okCancel, prompt, yesNo } from "./Control/Vue/Notifications/Prompts";

import AsyncComputed from "vue-async-computed";
import Snotify from "vue-snotify";
import { SnotifyService } from "vue-snotify/SnotifyService";
import { SnotifyConfig } from "./Control/Vue/Notifications/Shared/SnotifyConfig";
// vue-snotify forgets to patch the Vue interface for typescript, so we do that manually.
declare module "vue/types/vue" {
	interface Vue {
		$snotify: SnotifyService;
	}
}

import PortalVue from "portal-vue";

import VueContentPlaceholders from "vue-content-placeholders";
import "vue-content-placeholders/dist/vue-content-placeholders.css";

import VuePromiseBtn from "vue-promise-btn";
import "vue-promise-btn/dist/vue-promise-btn.css";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import ClickOutside from "vue-click-outside";

import { InlineSvgPlugin } from "vue-inline-svg";

import VuePromiseLink from "./Control/Vue/VuePromiseLink";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

import VueResizeText from "vue-resize-text";

import VueTimeago from "vue-timeago";

import I18NextModule from "./Control/Vue/Vuex/I18NextModule";
import { MiaPlaza } from "./Reinforced.Typings";
import SignalRConnection from "./utils/SignalRConnection";

import Log from "./utils/Log";
import Markdown from "./Markdown/Markdown";

// eslint-disable-next-line no-var
declare var window: any;

// eslint-disable-next-line @typescript-eslint/unbound-method
window.onerror = Log.onerror;
// eslint-disable-next-line @typescript-eslint/unbound-method
window.onunhandledrejection = Log.onUnhandledRejection;

Vue.config.errorHandler = (error) => {
	if (typeof(error) === "string") {
		void Log.error(error);
	}
	else {
		void Log.onerror("Error in Vue.js component", window.location, 0, 0, error);
	}
};

window.Navbar = Navbar;
window.Carousel = Carousel;
window.PasswordInputGroup = PasswordInputGroup;
window.CodeInputGroup = CodeInputGroup;
window.ProductSelector = ProductSelector;
window.PurchaseInformationBox = PurchaseInformationBox;
window.PurchaseSummary = PurchaseSummary;
window.StepWizard = StepWizard;
window.LoginFormChildren = LoginFormChildren;
window.ModerationNotifier = ModerationNotifier;
window.NewspaperEditor = NewspaperEditor;
window.ProfileForm = ProfileForm;
window.MemberList = MemberList;
window.SelectControlledControls = SelectControlledControls;
window.SignalRConnection = SignalRConnection;

const handlerTypes: { [name: string] : IHandlerType } = {
	AdminNavigationBar,
	Banner,
	Birthday,
	ChallengeListItem,
	ChildProfileInfoBlockEdit,
	Name,
	EndFriendshipButton,
	ProgressBar,
	StatusMessageEditor,
	TextEditor,
	LoginFormParent,
	ForgotPasswordParent,
	BootstrapSlider,
	ToggleableControl,
	CurrencyReward,
	ChildCredentials,
	AsyncCheckboxPanel,
	CheckboxPanel,
	DateCheckboxPanel,
	VideoCheckboxPanel,
	FeatureLevelSelect,
	FeatureLevelSettings,
	NewspaperAdSaveButton,
	PasswordInputGroup,
	ChangePassword,
	ProfileEmailChange,
	ChangeGameVideoPriority,
	ChildProfile,
};

// Make prompts available to legacy JS code
window.MiaOkCancel = okCancel;
window.MiaYesNo = yesNo;
window.MiaPrompt = prompt;
window.MiaErrorPrompt = errorPrompt;

// Allow content developers to see unreplaced text resource keys by typing MiaCulture() into the console.
window.MiaCulture = (culture?: string) => {
	const module = window.GetVuexModule(MiaPlaza.Control.Vue.I18Next.MODULE) as I18NextModule;
	module.setCulture(culture);
	return module.culture;
};

function initializeJQueryHandlerTypes() {
	for (const [name, handlerType] of Object.entries(handlerTypes)) {
		// eslint-disable-next-line no-loop-func
		jQuery(`[data-handler-type="${name}"]`).map((index: number, elem: HTMLElement) => new handlerType(jQuery(elem)));
	}
}

function initializeVue() {
	// Facebook-style loading indicators for lines of text
	Vue.use(VueContentPlaceholders);
	// Buttons that show a spinner until a promise is resolved
	Vue.use(VuePromiseBtn);
	// Extend VuePromiseBtn to also work with links
	Vue.use(VuePromiseLink);
	// Use toast messages with vue-snotify
	Vue.use(Snotify, SnotifyConfig);
	// Map vue content into HTML that is not controlled by Vue
	Vue.use(PortalVue);
	// Allow computed properties to return promises
	Vue.use(AsyncComputed);
	// Enable spinners on top of components marked with class="vld-parent"
	Vue.use(Loading);
	// Simple calendar which is used in reports tab on Parent portal
	Vue.component("v-date-picker", async () => {
		const vcalendar = await import("v-calendar") as any;
		vcalendar.setupCalendar({ datePickerShowDayPopover: false });
		return vcalendar.DatePicker;
	});
	// Simple routing for single-page application
	Vue.use(VueRouter);
	// Sane state machines with Vuex
	Vue.use(Vuex);
	Vue.use(PiniaVuePlugin);
	// Enable TextResources™
	// eslint-disable-next-line @typescript-eslint/unbound-method
	Vue.use(I18Next.install);
	// Enable Markdown
	// eslint-disable-next-line @typescript-eslint/unbound-method
	Vue.use(Markdown.install);
	// Enable tooltip functionality
	Vue.component("tippy", async () => {
		const tippy = await import("vue-tippy") as any;
		tippy.tippy.setDefaults({
			// Do not encircle clicked element
			a11y: false,
			arrow: true,
		});
		return tippy.TippyComponent;
	});
	// Standard html select does not allow us to load content with ajax
	// so we use vue select for such cases.
	Vue.use(vSelect);
	// Allows us to make inline svg from the src.
	Vue.use(InlineSvgPlugin);
	// Allows us to resize text depending on container width.
	Vue.use(VueResizeText);
	// Show date in time ago format, e.g. 5 minutes ago
	Vue.use(VueTimeago, {	locale: 'en' });
	// Allows us to handle clicks outside containers.
	Vue.directive("click-outside", ClickOutside);

	// Register all the controls that can be directly used in the web server"s HTML output as <mia-... />.
	Vue.component("mia-vuelist", VueList);
	Vue.component("mia-listmoduleregistration", ListModuleRegistration);
	Vue.component("mia-conversionmoduleregistration", ConversionModuleRegistration);
	Vue.component("mia-selecteditemmoduleregistration", SelectedItemModuleRegistration);
	Vue.component("mia-notifier", Notifier);
	Vue.component("mia-modal", Modal);
	Vue.component("mia-logo", Logo);
	Vue.component("mia-admintextresourcesearch", async () => (await import(/* webpackChunkName: "admin" */ "./AdminComponents")).AdminTextResourceSearch);
	Vue.component("mia-membersearch", async () => (await import("./Control/Vue/Lists/Searchable/MemberSearch.vue")));
	Vue.component("mia-teamsearch", async () => (await import("./Control/Vue/Lists/Searchable/TeamSearch.vue")));
	Vue.component("mia-newspaperpreviewsearch", async () => (await import("./Control/Vue/Lists/Searchable/NewspaperPreviewSearch.vue")));
	Vue.component("mia-reportsearch", async () => (await import("./Control/Vue/Lists/Searchable/ReportSearch.vue")));
	Vue.component('mia-toggleablehelpboxwrapper', async () => (await import("./Parents/Control/Vue/Toggleable/ToggleableHelpBoxWrapper.vue")));
	Vue.component("mia-errorpage", async () => await import("./Control/Vue/ErrorPage.vue"));
	Vue.component("mia-healthpage", async () => await import("./Control/Vue/HealthPage.vue"));
	Vue.component("mia-button", async () => await import("./Control/Vue/Button.vue"));
	Vue.component("mia-iframe", Iframe);
	Vue.component("mia-myworldroot", async () => await import("./mini-world/MyWorldRoot.vue"));
	Vue.component("mia-adminlostpetsearch", async () => (await import(/* webpackChunkName: "admin" */ "./AdminComponents")).AdminLostPetSearch);
	Vue.component("mia-moderatorsnote", async () => (await import(/* webpackChunkName: "admin" */ "./AdminComponents")).ModeratorsNote);
	Vue.component("mia-membershipdescription", MembershipDescription);
	Vue.component("mia-siteimpressum", SiteImpressum);
	Vue.component("mia-covervideopanel", CoverVideoPanel);
	Vue.component("mia-vimeoplayer", VimeoPlayer);
	Vue.component("mia-covervideoplaybutton", async () => await import("./Control/Vue/LandingPage/CoverVideoPlayButton.vue"));
	Vue.component("mia-rewardandsocialfeaturespanel", RewardAndSocialFeaturesPanel);
	Vue.component("mia-accessibilityinfopanel", AccessibilityInfoPanel);
	Vue.component("mia-membershipfliptiles", MembershipFlipTiles);
	Vue.component("mia-lessonroot", async () => await import("./lessons/LessonRoot.vue"));
	Vue.component("mia-videoroot", async () => await import("./video/VideoRoot.vue"));
	Vue.component("mia-useruploadedvideos", async() => await import("./video/UserUploadedVideos.vue"));
	Vue.component("mia-lessonsroot", async () => await import("./lessons/LessonsRoot.vue"));
	Vue.component("mia-loading", Loading);
	Vue.component("mia-transfertextresourcespreview", async () => (await import(/* webpackChunkName: "admin" */ "./AdminComponents")).TransferTextResourcesPreview);
	Vue.component("mia-admincustomgameeditor", async () => (await import(/* webpackChunkName: "admin" */ "./AdminComponents")).AdminCustomGameEditor);
	Vue.component("mia-learningpathassignments", async () => await import ("./curriculum/children/LearningPathAssignments.vue"));
	Vue.component("mia-learningpathcompleted", async () => await import("./curriculum/children/LearningPathCompleted.vue"));
	Vue.component("mia-rulesmodal", async () => await import("./Control/Vue/RulesModal.vue"));
	Vue.component("mia-funds", Funds);
	Vue.component("mia-miaprepannouncement", MiaPrepAnnouncement);
	Vue.component("mia-scopeandsequence", async () => await import ("./Parents/Control/Vue/ScopeAndSequence/ScopeAndSequence.vue"));
	Vue.component("mia-smileyselector", async () => await import ("./Control/Vue/SmileySelector.vue"));
	Vue.component("mia-videowatchheading", async () => await import ("./video/VideoWatchHeading.vue"));
	Vue.component("mia-shoutbox", async () => await import ("@/shoutbox/ShoutBox.vue"));
	Vue.component("mia-unfriendmemberlist", async () => await import ("@/Control/Vue/MemberActionableLists/MemberUnfriendList.vue"));
	Vue.component("mia-castleupgrade", async () => await import ("@/Control/Vue/MiniWorld/CastleUpgrade.vue"));
	Vue.component("mia-memberblocklist", async () => await import ("@/Control/Vue/MemberActionableLists/MemberUnblockList.vue"));
	Vue.component("mia-siteabout", async () => await import("@/public/site-about/SiteAbout.vue"));
	Vue.component("mia-siteguidelines", async () => await import("@/Parents/Control/Vue/SiteGuidelines.vue"));
	Vue.component("mia-miaprepbanner", async () => await import("@/Parents/Control/Vue/ParentsMenu/MiaPrepBanner.vue"));
	Vue.component("mia-newsletterpreviewbanner", async () => await import("@/Parents/Control/Vue/ParentsMenu/NewsletterPreviewBanner.vue"));
	Vue.component("mia-i18next", I18NextModuleRegistration);
	Vue.component("mia-weekloginreportsearch", async () => await import ("./Control/Vue/Lists/Searchable/WeekLoginReportSearch.vue"));
	Vue.component("mia-faq", async () => await import("./Control/Vue/Lists/Searchable/FAQ.vue"));
	Vue.component("mia-virtualcurrencymonitor", async () => await import ("./Control/Vue/Admin/VirtualCurrencyMonitor.vue"));
	Vue.component("mia-notificationtesterheading", async () => await import ("./Control/Vue/Admin/NotificationTester/NotificationTesterHeading.vue"));
	Vue.component("mia-miniworldhousecompetitionsidebar", async () => await import("./Control/Vue/MiniWorld/MiniWorldHouseCompetitionSidebar.vue"));
	Vue.component("mia-vimeouploadform", async () => await import("./Control/Vue/VimeoUploadForm.vue"));
	Vue.component("mia-teamachievementscompetitionsidebar", async () => await import("./Control/Vue/TeamAchievements/TeamAchievementsCompetitionSidebar.vue"));
	Vue.component("mia-activemoderators", async () => await import ("./Control/Vue/Admin/ActiveModerators.vue"));
	Vue.component("mia-beststreaksidebar", async () => await import("./Control/Vue/BestStreak/BestStreakSidebar.vue"));
	Vue.component("mia-scoopcompetitionsidebar", async () => await import("./Control/Vue/ScoopCompetition/ScoopCompetitionSidebar.vue"));
	Vue.component("mia-designstudioeditorheader", async () => await import("./mini-world/shop/DesignStudioEditorHeader.vue"));
	Vue.component("mia-designstudiotools", async () => await import("./mini-world/shop/DesignStudioTools.vue"));
	Vue.component("mia-memberminiworld", async () => await import("./common/MemberMiniworld.vue"));
	Vue.component("mia-gameheader", async () => await import("@/Control/Vue/GameHeader.vue"));
	Vue.component("mia-lessongameheader", async () => await import("./lesson/common/LessonGameHeader.vue"));
	Vue.component("mia-typinggametouchdevices", async () => await import("@/Control/Vue/Lesson/TypingGameTouchDevices.vue"));
	Vue.component("mia-informationbanner", async () => await import("./Parents/Control/Vue/InformationBanner.vue"));
	Vue.component("mia-tutorialvideosplaybutton", async () => await import("./Control/Vue/TutorialVideos/TutorialVideosPlayButton.vue"));
	Vue.component("mia-tutorialvideosdropdown", async () => await import("./Control/Vue/TutorialVideos/TutorialVideosDropdown.vue"));
	Vue.component("mia-tutorialvideosexpandable", async () => await import("./Control/Vue/TutorialVideos/TutorialVideosExpandable.vue"));
	Vue.component("mia-testimonialspanel", async () => await import("./Control/Vue/LandingPage/TestimonialsPanel.vue"));
	Vue.component("mia-header", Header);
	Vue.component("mia-giftcodegenerator", async () => (await import(/* webpackChunkName: "admin" */ "./Control/Vue/Admin/GiftCodeGenerator.vue")));
	Vue.component("mia-vue-feature-level-select", async () => await import(/* webpackChunkName: "admin" */ "./Control/Vue/VueFeatureLevelSelect.vue"));
	Vue.component("mia-vocabulary", async () => await import("@/Control/Vue/Lesson/Vocabulary.vue"));
	Vue.component("mia-lesson", async () => await import("@/lesson/Lesson.vue"));
	Vue.component("mia-legacylevelupscreen", async () => await import("@/Control/Vue/Lesson/LevelUpScreen.vue"));
	Vue.component("mia-studentannouncement", async () => await import("@/Control/Vue/StudentAnnouncement/StudentAnnouncement.vue"));

	Vue.component("mia-practice", async () => {
		return (await import("./Control/Vue/Lesson/LessonPracticeTypes/LessonPractice.vue"));
	});
	Vue.component("mia-assessment", async () => {
		return (await import("./Control/Vue/Lesson/LessonAssessment.vue"));
	});
	
	Vue.component("mia-vuegame", async () => {
		return await import("@miaplaza/early-learning-game");
	});

	// Register parent controls.
	Vue.component("mia-attacheditem", async() => await import("./Control/Vue/AttachedItem.vue"));
	Vue.component("mia-dateselector", DateSelector);
	Vue.component("mia-curriculumroot", async () => await import("./curriculum/parents/CurriculumRoot.vue"));
	Vue.component("mia-freshdesk", async () => (await import(/* webpackChunkName: "parent" */ "./ParentComponents")).Freshdesk);
	Vue.component("mia-scopeandsequencepanel", async () => ( await import(/* webpackChunkName: "parent" */ "./ParentComponents")).ScopeAndSequencePanel);
	Vue.component("mia-scopeandsequencebutton", async () => (await import(/* webpackChunkName: "parent" */ "./ParentComponents")).ScopeAndSequenceButton);
	Vue.component("mia-subjectpreview", async () =>(await import(/* webpackChunkName: "parent" */ "./ParentComponents")).SubjectPreview);
	Vue.component("mia-parentschildrenselector", async () => (await import(/* webpackChunkName: "parent" */ "./ParentComponents")).ParentsChildrenSelector);
	Vue.component("mia-addstudentbutton", async () => (await import(/* webpackChunkName: "parent" */ "./ParentComponents")).AddStudentButton);
	Vue.component("mia-glyphiconmenu", async () => (await import(/* webpackChunkName: "parent" */ "./ParentComponents")).GlyphiconMenu);
	Vue.component("mia-navbarblog", async () => (await import(/* webpackChunkName: "parent" */ "./ParentComponents")).NavbarBlog);
	Vue.component("mia-checkout", async () => (await import(/* webpackChunkName: "parent" */ "./ParentComponents")).Checkout);
	Vue.component("mia-managepaymentmethod", async () => (await import(/* webpackChunkName: "parent" */ "./ParentComponents")).ManagePaymentMethod);
	Vue.component("mia-mohsacademicplans", async () => (await import(/* webpackChunkName: "parent" */ "./ParentComponents")).MohsAcademicPlans);
	Vue.component("mia-gradebookroot", async () => (await import(/* webpackChunkName: "parent" */ "./ParentComponents")).GradebookRoot);
	Vue.component("mia-parentreviewuseruploadedcontent", async() => (await import(/* webpackChunkName: "parent" */ "./ParentComponents")).ParentReviewUserUploadedContent);
	Vue.component("mia-parentrevieweduseruploadedcontent", async() => (await import(/* webpackChunkName: "parent" */ "./ParentComponents")).ParentReviewedUserUploadedContent);
	Vue.component("mia-membershipreceipts", async () => (await import(/* webpackChunkName: "parent" */ "./ParentComponents")).MembershipReceipts);
	Vue.component("mia-assessmentprintbuttons", async() => (await import(/* webpackChunkName: "parent" */ "./ParentComponents")).AssessmentPrintButtons);
	Vue.component("mia-purchaseanalyticstracker", async () => (await import(/* webpackChunkName: "parent" */ "./ParentComponents")).PurchaseAnalyticsTracker);

	// Register admin controls
	Vue.component("mia-admingivefreemembership", async () => (await import(/* webpackChunkName: "admin" */ "./AdminComponents")).AdminGiveFreeMembership);
	Vue.component("mia-adminpaymenteditor", async () => (await import(/* webpackChunkName: "admin" */ "./AdminComponents")).AdminPaymentEditor);
	Vue.component("mia-admincouponcodeeditor", async () => (await import(/* webpackChunkName: "admin" */ "./AdminComponents")).AdminCouponCodeEditor);
	Vue.component("mia-pausesubscription", async () => (await import(/* webpackChunkName: "admin" */ "./AdminComponents")).PauseSubscription);
	Vue.component("mia-awardbutton", async () => (await import(/* webpackChunkName: "admin" */ "./AdminComponents")).AwardButton);
	Vue.component("mia-downloademails", async () => (await import(/* webpackChunkName: "admin" */ "./AdminComponents")).DownloadEmails);
	Vue.component("mia-regulatorsections", async () => (await import(/* webpackChunkName: "admin" */ "./AdminComponents")).RegulatorSections);
	Vue.component("mia-contentsynchronization", async () => (await import(/* webpackChunkName: "admin" */ "./AdminComponents")).ContentSynchronization);
	Vue.component("mia-pricingeditor", async () => (await import(/* webpackChunkName: "admin" */ "./AdminComponents")).PricingEditor);
	Vue.component("mia-mohsroot", async () => await import("./Control/Vue/Admin/Mohs/MohsRoot.vue"));
	Vue.component("mia-siteslowwarning", async () => (await import(/* webpackChunkName: "admin" */ "./AdminComponents")).SiteSlowWarning);
	Vue.component("mia-specialusers", async () => (await import(/* webpackChunkName: "admin" */ "./AdminComponents")).SpecialUsers);
	Vue.component("mia-virtualcurrencycompetition", async () => (await import (/* webpackChunkName: "admin" */ "./AdminComponents")).VirtualCurrencyCompetition);
	Vue.component("mia-adminreviewuseruploadedcontent", async () => (await import(/* webpackChunkName: "admin" */ "./AdminComponents")).AdminReviewUserUploadedContent);
	Vue.component("mia-lessonfeedback", async () => (await import(/* webpackChunkName: "admin" */ "./AdminComponents")).LessonFeedback);
	
	const router = new VueRouter({
		// A better solution would be returning to savedPosition when pressing the
		// the back button. But since some of the components only load when scrolled 
		// down due to using InfiniteLoadingList component, we can't arrive to the
		// savedPosition unless we scroll manually. To avoid this problem we decided
		// to always go to the top of the page to be more consistent.
		scrollBehavior() {
			return { x: 0, y: 0 };
		}
	});

	const store = new Vuex.Store({});
	const pinia = createPinia();
	pinia.use(piniaPluginPersistedstate);

	// We attach a global Vue instance to the outermost <form>. It seems to be
	// a bad idea to attach to <body> (see google) so we attach to
	// something inside body that contains virtually everything.
	// eslint-disable-next-line no-new
	new Vue({
		// We replace Vue's default delimiters {{ }} with random strings. We do not want to use
		// interpolation in the template that is rendered by the web server but only in the template
		// blocks of our components. Note that we already set v-pre on many controls on the web server
		// which disables interpolation but we can not always set this (e.g. not on literals.)
		delimiters: [Math.random().toString(36), Math.random().toString(36)],
		el: "form#Form",
		mounted() {
			// Vue replaces the elements after events were added to them in domready.
			// Thus we should initialize jQuery handler types in the mounted() hook.
			// See more in disscussion here https://github.com/vuejs/vue/issues/4395.
			initializeJQueryHandlerTypes();
		},
		router,
		store,
		pinia
	});

	// Make Vue available for legacy js code.
	window.Vue = Vue;

	// Make Vuex available for js code
	window.GetVuexModule = (module: string) => useModule(store, [module]);

	// Axios Configuration
	setup();

	// Track active member sessions via SignalR
	void SignalRConnection.connect(MiaPlaza.SignalR.SessionHub.PATH, (connection) => {
		connection.on("ShowNotification", (notification: MiaPlaza.Control.Vue.Notifications.Shared.INotification) => {
			Notifier.show(notification);
		});
		connection.on("RequestTimezoneUpdate", () => {
			void connection.invoke("UpdateTimezone", Intl.DateTimeFormat().resolvedOptions().timeZone);
		});
	});

}

jQuery(() => {
	initializeVue();
});
